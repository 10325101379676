<template>
    <div>
        <div v-if="isDesktop" class="main-header">
            <DesktopHeader />
        </div>
        <div v-else>
            <Header />
        </div>
        <slot />
        <div v-if="isDesktop">
            <div class="start-footer">
                <div class="start-footer__links">
                    <div v-if="pageTemplate.col1 && pageTemplate.col1.show" class="start-footer__links__main">
                        <div class="start-footer__links__main__logo"></div>
                        <ul v-if="pageTemplate.col1.links" class="start-footer__links__list">
                                <li class="start-footer__links__item">Поддержка</li>
                        </ul>
                    </div>
                    <div style="display: flex">
                    <div v-if="pageTemplate.contacts" class="start-footer__links__contacts" style="margin-right: 40px">
                        <div class="start-footer__links__block-title">Контакты</div>
                        <div class="start-footer__links__contacts__key">По всей России</div>
                        <div class="start-footer__links__contacts__value">+7 (495) 215-00-95</div>
                    </div>
                    <div v-if="pageTemplate.contacts" class="start-footer__links__contacts">
                        <div class="start-footer__links__contacts__social">
                            <!-- <a
                                v-if="pageTemplate.contacts.fb"
                                :href="pageTemplate.contacts.fb"
                                target="_blank">
                                <div class="start-footer__links__contacts__social__item facebook"></div>
                            </a> -->
                            <a
                                v-if="pageTemplate.contacts.vk"
                                :href="pageTemplate.contacts.vk"
                                target="_blank">
                                <div class="start-footer__links__contacts__social__item VK"></div>
                            </a>
                            <a
                                v-if="pageTemplate.contacts.telegram"
                                :href="pageTemplate.contacts.telegram"
                                target="_blank">
                                <div class="start-footer__links__contacts__social__item telegram"></div>
                            </a>
                            <!-- <a
                                v-if="pageTemplate.contacts.instagram"
                                :href="pageTemplate.contacts.instagram"
                                target="_blank">
                                <div class="start-footer__links__contacts__social__item instagram"></div>
                            </a> -->
                        </div>
                        <div class="start-footer__links__contacts__key">Корпоративная почта</div>
                        <div class="start-footer__links__contacts__value"><a :href="`mailto: support@getadventures.co`">support@getadventures.co</a></div>
                        
                    </div>
                    </div>
                </div>
                <div class="start-footer__static">
                    <div class="start-footer__static__item">© GetAdventures, 2022</div>
                    <div class="start-footer__static__item">Правила использования сайта</div>
                    <div class="start-footer__static__item">Политика конфиденциальности</div>
                    <!-- <div class="start-footer__static__affetta">
                        <div class="start-footer__static__item">Дизайн от AFFETTA</div>
                        <img src="../../assets/AFFETTA.svg" style="width: 25px; heigh: 25px;" />
                    </div> -->
                </div>
            </div>
        </div>
        <div v-else>
            <MobileFooter />
        </div>
        <PopupLogin />
    </div>
    
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    import DesktopHeader from '../components/desktop/Header.vue';
    import Header from '../components/mobile/Header.vue';
    // import Footer from '../components/desktop/Footer.vue';
    import MobileFooter from '../components/mobile/Footer.vue';
    import PopupLogin from '../components/common/PopupLogin.vue';

    export default {
        name: 'LayoutDefault',
        components: {
            DesktopHeader,
            Header,
            MobileFooter,
            PopupLogin
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.footer
            }),
        },
        async mounted() {
            if(!this.user._id) {
                try {
                    await store.dispatch('auth/fetch');
                } catch(e) {}
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            async home() {
                await this.$router.push({ name: 'home' });
            },
            async goTo(name) {
                if(this.user._id) {
                    await this.$router.push({ name });
                } else {
                    this.authorization()
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async logout() {
                if(this.user._id) {
                    await store.dispatch('auth/logout');
                    store.commit('trips/CLEAR_ENTITIES');
                    await store.dispatch('trips/fetch', { filter: { type: TRIP_TYPE.TOUR_LEADER, status: TRIP_STATUS.ACTIVE } });
                    await this.$router.push({ name: 'home' });
                }
            },
        },
    }
</script>


<style lang="scss">
.start-footer {
    padding: 0 32px;
    &__links {
        width: 100%;
        padding: 40px 0 50px;
        @media all and ( min-width: 1400px) {
            margin: 40px auto 0;
            max-width: 1400px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media all and (max-width: 768px) {
            flex-direction: column;
            justify-content: space-between;
            margin: 40px 0 0;
            padding: 0 20px 30px;
            height: auto;
        }
        &__main {
            &__logo {
                width: 200px;
                height: 30px;
                margin-bottom: 18px;
                background: url(../assets/Main-logo.svg);
                background-size:contain ;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }
        &__block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #273155;
            text-align: left;
            margin-bottom: 18px;
        }
        &__list {
            list-style-type: none;
            text-align: left;
            padding-left: 0 !important;
            @media all and (max-width: 768px) {
                display: none;
            } 
        }
        &__type {
            @media all and (max-width: 768px) {
                display: none;
            }
        }
        &__country {
            @media all and (max-width: 768px) {
                display: none;
            }
        }
        &__item {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #A9AEB2;
            margin-bottom: 12px;
            cursor: pointer;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &__contacts {
            text-align: left;
            &__key {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color:  #273155;
                opacity: 0.4;
                margin-bottom: 8px;
            }
            &__value {
                font-weight: 500;
                font-size: 18px;
                line-height: 115%;
                color: #273155;
                margin-bottom: 20px;
                > a {
                    text-decoration: none;
                    color: #273155 !important;
                }
            }
            &__social {
                display: flex;
                flex-direction: row;
                &__item {
                    width: 32px;
                    height: 32px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid #93A3B4;
                    border-radius: 50%;
                    box-sizing: border-box;
                    margin-right: 12px;
                    margin-bottom: 8px;
                    cursor: pointer;
                    &:last-of-type {
                        margin-right: none;
                    }
                    &.facebook {
                        background-image: url(../assets/Facebook.svg);
                    }
                    &.VK{
                        background-image: url(../assets/VK.svg);
                    }
                    &.telegram {
                        background-image: url(../assets/Telegram.svg);
                        background-position: 6px 8px;
                    }
                    &.instagram {
                        background-image: url(../assets/Instagram.svg);
                    }
                }
            }
        }
    }
    &__static {
        width: 100%;
        max-width: 1400px;
        height: 62px;
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: space-between;
        border-top: 1px solid #EBEBEB;
        opacity: 0.5;
        @media all and (max-width: 768px) {
            flex-direction: column;
            height: 62px;
            padding: 12px 20px;
        }
        &__item {
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            color: #505872;
        }
        &__affetta {
            display: flex;
            flex-direction: row;
        }
    }
}
</style>
